import React from "react"
import AiInEducation1 from "../../../images/ai-in-education1.png"
import AiInEducation2 from "../../../images/ai-in-education2.png"
import infoEmail from "../../../images/info-email.png"
import Button from "../../../components/button"
import './styles.css';
const FundamentalsOfAiInEducation = () => {
  const styles = {
    heading: {
      marginBottom: "10px",
      marginTop: "30px",
    },
    button: {
      cursor: "pointer",
      color: "white",
      textAlign: "center",
      fontWeight: 700,
      backgroundColor: "#092c4c",
      fontSize: "18px",
      padding: "15px 35px",
      borderRadius: "30px",
      display: "block",
      marginTop: "20px",
    },
    div: {
      marginBottom: "35px",
    },
  }

  return (
    <div>
      <img src={AiInEducation1} style={{width:"100%"}}/>
       <h6 style={styles.heading}>Overview</h6>
      <div style={styles.div}>
      The purpose of this micro-credential is to equip educators with foundational knowledge and practical skills in AI applications in education. It covers essential AI concepts, tools for lesson planning, personalised learning, classroom management, and ethical considerations. Educators will learn how to integrate AI into their teaching practices to enhance student engagement and educational outcomes while navigating ethical aspects of AI technologies.
      </div>
      <div style={styles.div}>
      Classes are very flexible with options of 10 virtual Wednesday nights 7-8PM, in person block courses and virtual block courses in the holidays. Contact us now to make it happen for you!
      </div>
      <div style={styles.div}>
      Educators will integrate their learning and project work towards assessments into their weekly work schedules in their respective schools and educational organisations. This integration ensures that the theoretical and practical aspects of the micro-credential are directly applied to real-world educational settings, enhancing the relevance and impact of their learning experiences.
      </div>
      {/* <img src={AiInEducation2} style={{width:"100%"}}/> */}
      <div
      style={{
        position: 'relative',
        width: '100%',
        height: 0,
        paddingTop: '56.25%', // 16:9 aspect ratio
        paddingBottom: 0,
        boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
        marginTop: '1.6em',
        marginBottom: '0.9em',
        overflow: 'hidden',
        borderRadius: '8px',
        willChange: 'transform',
      }}
    >
      <iframe
        loading="lazy"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          border: 'none',
          padding: 0,
          margin: 0,
        }}
        src="https://www.canva.com/design/DAGMXLlZGwk/7Apb7-_qggfz6Ec0t1LDow/view?embed"
        allowFullScreen
        title="Fundamentals of AI in Education NZQA accredited"
      ></iframe>
      <a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGMXLlZGwk&#x2F;7Apb7-_qggfz6Ec0t1LDow&#x2F;view?utm_content=DAGMXLlZGwk&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener">Fundamentals of AI in Education NZQA accredited</a> by Craig Hansen
    </div>
      <div style={{marginBottom: "35px",marginTop:"20px"}}>
      On successful completion of this micro-credential, learners/ākonga will be able to:
      <ul>
        <li>Understand and articulate fundamental AI concepts and their specific applications within educational settings.</li>
        <li>Integrate AI tools into lesson planning and personalised learning experiences effectively.</li>
        <li>Navigate and address ethical considerations associated with AI deployment in education, promoting responsible and fair use of AI technologies. </li>
      </ul>
      <div style={{marginTop:"40px"}}><a href="https://docs.google.com/forms/d/e/1FAIpQLSf3lEQ9MHRJFhrPFUfEqL4qxL_UrO28QJlnYjb27V2I1lYcvQ/viewform" target="_blank" class="btn-nl btn-sec enroll-button" style={{textDecoration:"none",marginRight:'20px'}}>Enrol</a></div>
      </div>
      
      <div className="contact-content" style={{width:"100%",background:"#083051",paddingTop:"30px",paddingBottom:"30px"}}>
              <h3 className="contact-heading">We'd love to hear from you</h3>
              <span style={{ marginBottom: "10px" }}>
                You can contact us at
              </span>
              <img
                src={infoEmail}
                style={{ marginBottom: "20px"}}
                alt="email address" className="responsive-img"
              />
              <span style={{ marginBottom: "20px" }}>
                and one of our team will be in touch
              </span>
              <a href="mailto:contact@summitinstitute.ac.nz?subject=Mail from Summit Institute">
                <Button colorSchema="sec">Email us</Button>
              </a>
            </div>
    </div>
  )
}

export default FundamentalsOfAiInEducation
